import _ from 'lodash';
import React, { Component } from 'react';
import {Route, Switch} from 'react-router-dom';

import HRDProgram from 'js/container/HRDProgram';
import HumanResourcePool from 'js/container/HumanResourcePool';
import BusinessAccountSettings from 'js/container/BusinessAccountSettings';
import BusinessAccountManagerAcceptance from 'js/container/BusinessAccountManagerAcceptance';
import { requireManagerRole } from 'js/hoc/BusinessHOC';
import { BusinessAccountContext } from 'js/context/BusinessAccountContext';

import { AppContext } from 'js/context/AppContext';
import { LinkGenerator } from 'js/util/LinkGenerator';

import 'scss/scene/business_home.scss';

class BusinessDashboard extends Component {

  constructor(props){
    super(props);
    this.state = {
      businessInfo: undefined,
      businessId: this.props.match.params.id
    }
  }

  componentWillMount(){
    var businessId = this.state.businessId;

    fetch(
      _.template("<%= host %>rest/account/business/<%= id %>")({
        host: process.env.REACT_APP_API_HOST, 
        id: businessId}),
      {
        credentials: 'include'
      }
      
    ).then(response => response.json())
    .then(data => this.handleData(data))
    .catch((error) => {
      console.log(error);
    });
  }

  handleData(data){
    // redefine structure
    var businessInfo = data.businessAccount;
    businessInfo.managerRole = data.managerRole;

    this.setState({
      businessInfo: businessInfo,
      routePath : {
        hrdProgram : _.template("/app/business/<%= businessId %>/program")(this.state),
        hrPool : _.template("/app/business/<%= businessId %>/hr-pool")(this.state),
        settings : _.template("/app/business/<%= businessId %>/settings")(this.state),
        acceptance : _.template("/app/business/<%= businessId %>/acceptance")(this.state)
      },
      update: this.handleData.bind(this) // 함수에 전달되는 데이터 구조가 기업계정 정보조회 결과와 같다고 가정하고 있다.
    });
  }

  render() {

    return (
      <AppContext.Consumer>
        {({user, legacyAccountId}) => {
          if(!this.state.businessInfo){
            return <div/>;
          }

          if(!user && !legacyAccountId){
            return (
              <div className="jumbotron jumbotron-fluid">
                <div className='container'>
                  <h3><span className='badge badge-secondary'>{this.state.businessInfo.name}</span> 기업계정 관리자입니까?  </h3>
                  <p className="lead">로그인이 필요합니다.</p>
                  <a href={LinkGenerator.getLogin()} className="btn btn-primary btn-lg" >로그인</a>
                  <hr className="my-4"/>
                  <p>일반 사용자를 위한 기업페이지는 준비중입니다.</p>
                </div>
              </div>
            )
          }

          

          return (
            <BusinessAccountContext.Provider value={this.state}>
              <Switch>
                <Route path={this.state.routePath.hrdProgram} component={requireManagerRole(HRDProgram)} />
                <Route path={this.state.routePath.hrPool} component={requireManagerRole(HumanResourcePool)} />
                <Route path={this.state.routePath.settings} component={requireManagerRole(BusinessAccountSettings)} />
                <Route path={this.state.routePath.acceptance} component={BusinessAccountManagerAcceptance} />
              </Switch>
            </BusinessAccountContext.Provider>
          );

        }}
      </AppContext.Consumer>
    );
  }
}

export default BusinessDashboard;
