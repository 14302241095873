import React from 'react';
import { AppContext } from 'js/context/AppContext';
import { LinkGenerator } from 'js/util/LinkGenerator';

import 'scss/component/splash.scss';

const DesktopGlobalNavigation = (props) => {
    return (
        <AppContext.Consumer>
            {({user}) => (
                <div id="desk_global_nav" className='container-fluid'>
                    <div>
                    <img className='brand_logo' 
                        alt='leadme brand logo' 
                        src='https://file.leadme.today/web-static/svg/brand-logo.svg'/>
                    <h1 className='vertical_title'>비즈니스</h1>
                    </div>
                    <div className='flex_spacer'/>
                    <div>
                        {user ? user.name : <a href={LinkGenerator.getLogin()}>로그인</a>}
                    </div>
                </div>
            )}
        </AppContext.Consumer>
    )
}

export default DesktopGlobalNavigation;