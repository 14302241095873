import moment from 'moment';
import 'moment/locale/ko';
moment.locale('ko');

export default {
    fromNow: function(someTime, clippingTime){
        if(!someTime){
            return null;
        }
        
        var createTime = moment(someTime);
        var now = moment();
        
        if(now.diff(createTime, 'minutes') < 1){
            createTime = "방금 전";
        }else if(now.diff(createTime, 'days') < 7){
            createTime = createTime.fromNow();
        }else if(now.year() == createTime.year()){
            createTime = createTime.format('M월 D일' + (clippingTime ? '' : ' HH:mm'));
        }else{
            createTime = createTime.format('YYYY-MM-DD' + (clippingTime ? '' : ' HH:mm'));
        }
        
        return createTime;
    },

    dateToString: function(date, formatStr){
        if(!date){
            return null;
        }

        return moment(date).format(formatStr || 'YYYYMMDD');
    },
    
    dDate: function(standardDate, someTime) {
        var _now = moment();
        var _diffDate = parseInt(standardDate - _now.diff(moment(someTime), 'days'));

        if (_diffDate > 0) {
            return 'D-' + _diffDate;
        } else if (_diffDate === 0) {
            return 'D-day'
        } else {
            return 'D+' + (-_diffDate);
        }
    },

    dateToYmdInt: function(date){
        if(!date){
            return null;
        }
        return parseInt(moment(date).format('YYYYMMDD'));
    },
    
    intYmdToMoment: function(ymdIntValue){
        return moment(ymdIntValue.toString(), 'YYYYMMDD');
    },
    
    intYmdToString: function(ymdIntValue, alternative){
        
        if(ymdIntValue <= 0){
            return alternative || "?";
        }
        
        var str = Math.floor(ymdIntValue / 10000); // years
        
        str += '. ' + this.leadToZero(Math.floor(ymdIntValue / 100) % 100, 2)   // month
        
        var d = ymdIntValue % 100;
        
        if(d > 0 && d < 32){
            str += '. ' + this.leadToZero(d, 2);
        }
        
        return str;
    },
    
    leadToZero: function(value, precision){
        var result = value.toString();
        
        for(var i = 0; i < (precision - result.length); i++){
            result = '0' + result;
        }
        
        return result;
    },

    /**
     * 지정된 나이보다 많은지 판단
     */
    olderThan: function(age, dateValue){
        return moment().subtract(age, 'years').isAfter(dateValue)
    }
}
