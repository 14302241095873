import React, { Component } from 'react';

class DefaultScene extends Component {
  render() {
    return (
        <header className="App-header">
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
            {process.env.REACT_APP_API_HOST}
        </header>
    );
  }
}

export default DefaultScene;
