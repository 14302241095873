import _ from 'lodash';
import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import {BusinessAccountContext} from 'js/context/BusinessAccountContext';

class BusinessAccountManagerAcceptance extends Component {

  constructor(props){
    super(props);
    this.state = {
      acceptanceStatus : 'check'
    }
  }

  componentWillMount(){
    fetch(
      _.template("<%= host %>rest/account/business/<%= id %>/manager/acceptance")({
        host: process.env.REACT_APP_API_HOST, 
        id: this.context.businessInfo.id
      }),
      {
        method: 'PUT',
        credentials: 'include'
      }      
    ).then(response => response.json())
    .then(data => this.successHandle(data))
    .catch((error) => this.failHandler(error));
  }

  successHandle(data){
    document.location.href = this.context.routePath.hrPool;
  }

  failHandler(error){
    console.log(error);
    this.setState({
      acceptanceStatus: "fail"
    })
  }

  render(){
      if(this.state.acceptanceStatus === 'check'){
        return (
          <div className="jumbotron jumbotron-fluid">
              <div className='container'>
                  <h3><span className='badge badge-secondary'>{this.context.businessInfo.name}</span> 기업계정 접근 관한을 확인하고 있습니다.  </h3>
                  <p className="lead">잠시만 기다려주세요.</p>
              </div>
          </div>
        );
      }else if(this.state.acceptanceStatus === 'fail'){
        return (
          <div>관리자 초대목록에서 찾지 못했습니다.</div>
        );
      }else{
        return <Redirect to={this.context.routePath.hrPool}/>
      }
  }
}

BusinessAccountManagerAcceptance.contextType = BusinessAccountContext;

export default BusinessAccountManagerAcceptance;