import React from 'react';
import {BusinessAccountContext} from 'js/context/BusinessAccountContext';

const BusinessAccountSettings = (props) => {
    return (
      <BusinessAccountContext.Consumer>
        {(data) => {
          return (
              <div>settings</div>
          )
        }}
      </BusinessAccountContext.Consumer>
    )
}

export default BusinessAccountSettings;