import React from 'react';
import 'scss/component/splash.scss';

const Splash = (props) => {
    return (
        <div id="app_splash" className='d-flex justify-content-center align-items-center'>
            <div className='loading_indct'>
                <img src="https://file.leadme.today/web-static/img/common/loading_indicator_libo.gif" alt="Loading.."/>
                <p className='mt-1'>열심히 불러오는 중</p>
            </div>
        </div>
    )
}

export default Splash;