import _ from 'lodash';
import React, { Component } from 'react';
import {BusinessAccountContext} from 'js/context/BusinessAccountContext';
import 'bootstrap/js/dist/dropdown';
import 'scss/container/hr-pool.scss';

class HumanResourcePool extends Component {   
    
    constructor(props){
        super(props);
        this.state = {
            pool: [],
            searchText: "",
            search: ""
        }

        this.changeSearchText = this.changeSearchText.bind(this);
        this.executeSearch = this.executeSearch.bind(this);
    }

    componentWillMount(){
        fetch(
            _.template("<%= host %>rest/account/business/<%= id %>/hr-pool")({
              host: process.env.REACT_APP_API_HOST, 
              id: this.context.businessInfo.id}),
            {
            credentials: 'include'
            }
        ).then(response => response.json())
        .then(data => this.handleData(data))
        .catch((error) => {
            console.log(error);
        });
    }
      
    changeSearchText(e){
        this.setState({
            searchText: e.target.value
        });
    }

    executeSearch(){
        this.setState({
            search: this.state.searchText
        });
    }

    handleData(data){
        data = data.map((item) => {
            var searchText = "";
            searchText += item.user.displayName;
            searchText += item.user.realName;
            
            if(item.user.careerSummary){
                searchText += item.user.careerSummary.name;
                searchText += item.user.careerSummary.properties;
            }

            searchText += item.user.email;
            item.searchText = searchText;
            return item;
        })

        data.sort((a, b) => {
            var nameA = a.user.realName;
            var nameB = b.user.realName;
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            // 이름이 같을 경우
            return 0;
        });

        this.setState({
            pool: data
        })
    }
    
    
    render(){
        var _this = this;
        var result = this.state.pool.map((data) => {
            if(_this.state.search && _this.state.search.length > 0){
                if(data.searchText.indexOf(_this.state.search) < 0){
                    return null;
                }
            }
            return data;
        });
        return (
                <div>
                    <div className='p-2'>
                        <h2>인재풀</h2>
                        <p>{this.context.businessInfo.name}를 팔로우하거나 관련된 커리어가 등록된 사용자들의 최신 업데이트를 조회 할 수 있습니다.</p>
                    </div>
                    <div className='hr_utilty_bar d-flex flex-row align-items-center p-2'>
                        <form className="form-inline">
                            <div className="form-group mr-1">
                                <label htmlFor='hr-search' className='sr-only'>통합검색</label>
                                <input id='hr-search' className='form-control form-control-sm' placeholder='통합검색' onChange={this.changeSearchText} value={this.state.searchText}/>
                            </div>
                            <button type="button" className='btn btn-primary btn-sm' onClick={this.executeSearch}>검색</button>
                        </form>
                        <div className='flex-grow-1'/>
                        <div className="">Total : {this.state.pool.length}</div>
                        
                    </div>
                    <div className='hr_list'>
                        <table className="table table-hover mb-0">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">프로필</th>
                                    <th scope="col">등록구분</th>
                                    <th scope="col">경력</th>
                                    <th scope="col">역량</th>
                                    <th scope="col">이메일</th>
                                </tr>
                            </thead>
                            <tbody>
                                {result.map(function(data, index, list){
                                    if(!data) return null;

                                    return (
                                        <tr key={data.id}>
                                            <th scope="row"></th>
                                            <td>
                                                <div className='d-flex flex-row align-items-center lm_profile_hr'>
                                                        <img className='mr-2' src={data.user.avatarPath} alt='user profile'/>
                                                        <div>
                                                            <a href={"/rest/app/@" + data.user.id} target="_blank">
                                                            <p className='mb-0'>
                                                                {data.user.displayName} ({data.user.realName})
                                                            </p>
                                                            {_this.generateSummary(data.user.careerSummary)}
                                                            </a>
                                                        </div>
                                                </div>
                                            </td>
                                            <td>{data.programName}</td>
                                            <td>{_this.convertDuration(data.user.workDuration)}</td>
                                            <td>-</td>
                                            <td>{data.user.email}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
        );
    }

    generateSummary(summaryData){
        if(!summaryData){
            return null
        }

        return <small><strong>{summaryData.name}</strong> {summaryData.properties}</small>;
    }

    convertDuration(value){
        if(value == 0){
            return "-";
        }

        var result = "";
        if(value >= 12){
            result += Math.floor(value / 12) + "년 ";
        }

        if(value % 12 > 0){
            result += (value % 12) + "개월";
        }

        return result;
    }
  }

  HumanResourcePool.contextType = BusinessAccountContext;

  export default HumanResourcePool;