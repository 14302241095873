import _ from 'lodash';
import React, { Component } from 'react';
import {Route, Switch} from 'react-router-dom';
import classNames from 'classnames';
import {DefaultScene, BusinessDashboard} from 'js/scene';
import {DesktopGlobalNavigation, Splash} from 'js/component';
import {AppContext} from 'js/context/AppContext';
import { library, config } from '@fortawesome/fontawesome-svg-core'
import { fal } from '@fortawesome/pro-light-svg-icons'
import 'App.scss';

library.add(fal);

class App extends Component {

  constructor(props){
    super(props)

    this.state = {
      initialized: false,
      contextData: null
    };
  }

  componentWillMount(){
    fetch(
      _.template("<%= host %>rest/app/context")({
        host: process.env.REACT_APP_API_HOST
      }),
      {
        credentials: 'include'
      }
    ).then(response => response.json())
    .then(data => this.handleContextData(data))
    .catch((error) => {
      console.log(error);
    });
  }

  handleContextData(data){
    this.setState({
      initialized: true,
      contextData: data
    })
  }

  render() {

    if(!this.state.initialized){
      return <Splash/>;
    }

    return (
      <AppContext.Provider value={this.state.contextData}>
        <div id="app_wrapper" className={classNames('layout_full_vertical')}>
          <DesktopGlobalNavigation/>
          <Switch>
            <Route path="/app/business/:id" component={BusinessDashboard} />
            <Route path="/app/business" component={DefaultScene} />
          </Switch>
        </div>
      </AppContext.Provider>
    );
  }
}

export default App;
