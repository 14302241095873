import React from "react";
import {NavLink} from 'react-router-dom';
import {BusinessAccountContext} from 'js/context/BusinessAccountContext';

export const requireManagerRole = function(WrappedComponent, requireRole){
    return class extends React.Component{
        render(){
            return (
                <BusinessAccountContext.Consumer>
                    {({businessInfo, routePath}) => {
                        if(!businessInfo.managerRole || businessInfo.managerRole === 'NONE'){
                            return(
                                <div className="jumbotron jumbotron-fluid">
                                    <div className='container'>
                                        <h3><span className='badge badge-secondary'>{businessInfo.name}</span> 기업계정 접근 권한이 없습니다.  </h3>
                                        <p className="lead">기업계정 소유자에게 문의하세요.</p>
                                        <a href={process.env.REACT_APP_API_HOST}>리드미 홈으로 돌아가기</a>
                                        <hr className="my-4"/>
                                        <p>일반 사용자를 위한 기업페이지는 준비중입니다.</p>
                                    </div>
                                </div>
                            )
                        }else{
                            return (
                                <>
                                    <div className='local_nav container-fluid'>
                                        <div className='d-flex flex-row align-items-center'>
                                        <div className='business_name p-1 px-2'>
                                            {businessInfo.name}
                                        </div>
                                        <nav className='nav'>
                                            <NavLink className='nav-link' exact activeClassName='active' to={routePath.hrdProgram}>인재개발</NavLink>
                                            <NavLink className='nav-link' exact activeClassName='active' to={routePath.hrPool}>인재풀</NavLink>
                                        </nav>
                                        </div>
                                    </div>

                                    <div className='container-fluid content_area mt-3 mb-5'>
                                        <div className='row'>
                                        <div className='col'/>
                                        <div className='col-xl-10'>
                                            <WrappedComponent {...this.props}/>
                                        </div>
                                        <div className='col'/>
                                        </div>
                                    </div>
                                </>
                            ); 
                        }
                    }}
                </BusinessAccountContext.Consumer>
            )
        }
    }
}
