import _ from 'lodash';
import React, { Component } from 'react';
import {Route, Link} from 'react-router-dom';
import {BusinessAccountContext} from 'js/context/BusinessAccountContext';
import HRDProgramDetail from 'js/container/HRDProgramDetail';
import MomentHelper from 'js/util/MomentHelper';
import 'bootstrap/js/dist/dropdown';
import 'scss/container/hrd-program.scss';

class HRDProgram extends Component {   
    
    constructor(props){
        super(props);
        this.state = {
            programs: []
        }
    }

    componentWillMount(){
        fetch(
            _.template("<%= host %>rest/business/<%= id %>/programs")({
              host: process.env.REACT_APP_API_HOST, 
              id: this.context.businessInfo.id}),
            {credentials: 'include'}
        ).then(response => response.json())
        .then(data => this.handleData(data))
        .catch((error) => {
            console.log(error);
        });
    }
      
    handleData(data){
        this.setState({
            programs: data
        })
    }
    
    
    render(){
        return (
                <div>
                    <div className='p-2'>
                        <h2>인재개발 프로그램</h2>
                        <p>
                            교육, 연수 프로그램을 만들고 관리 할 수 있습니다.<br/>
                            프로그램에 초대 및 수락한 사용자는 인재풀에 자동등록되며 사용자에게는 인증된 커리어가 발급됩니다.
                        </p>
                    </div>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='hrd_list mt-2 col-md-6'>                        
                                {this.state.programs.map((data, index, list) => (
                                    <Link key={data.id} to={this.props.match.path + "/" + data.id}>
                                        <div className='card'>
                                            <div className='card-body'>
                                                <h5 className='card-title'>{data.name}</h5>
                                                <p className='card-text'>
                                                    {data.summary}
                                                </p>
                                                <p>
                                                    운영 기간 : {MomentHelper.intYmdToString(data.start)} - {MomentHelper.intYmdToString(data.end, "진행중")}
                                                </p> 
                                            </div>
                                            <div className='card-footer'>
                                                <small className='text-muted'>{MomentHelper.fromNow(data.createTime)}</small>
                                            </div>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                            <div className='col-md-6'>
                                <Route path={this.props.match.path + "/:id"} component={HRDProgramDetail} />
                            </div>
                        </div>
                    </div>
                </div>
        );
    }

  }

  HRDProgram.contextType = BusinessAccountContext;

  export default HRDProgram;