import _ from 'lodash';
import React, { Component } from 'react';
import classNames from 'classnames';
import MomentHelper from 'js/util/MomentHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'scss/container/hrd-program.scss';


const RoleLabel = {
    STUDENT: "교육생",
    TEACHER: "강사",
    STAFF: "스탭"
}

const StatusLabel = {
    INVITED: {
        label: "초대됨",
        style: "light"
    },
    ACCEPT: {
        label: "인증됨",
        style: "success"
    },
    INVALID: {
        label: "무효",
        style: "warning"
    },
    CANCELED: {
        label: "초대취소됨",
        style: "secondary"
    }
}

class HRDProgramDetail extends Component {   
    
    constructor(props){
        super(props);
        this.state = {
            programId: this.props.match.params.id,
            content: [],
            paginationStart: 0,
            paginationSize: 10,
            totalElements: 0,
            totalPages: 0 
        }

        this.fetchData = this.fetchData.bind(this);
    }

    componentWillMount(){
        this.fetchData(0)
    }

    handleData(data){
        this.setState(data);
    }

    fetchData(page){
        fetch(
            _.template("<%= host %>rest/business/program/<%= id %>/members?page=<%= page %>&size=20&sort=updated,desc")({
              host: process.env.REACT_APP_API_HOST, 
              id: this.state.programId,
              page: page
            }),
            {credentials: 'include'}
        ).then(response => response.json())
        .then(data => this.handleData(data))
        .catch((error) => {
            console.log(error);
        });
    }

    render(){

        return (
            <div className='member_list'>
                <div className='d-none'>
                <div className='hr_utilty_bar d-flex flex-row align-items-center p-2'>
                    <form className="form-inline">
                        <div className="dropdown">
                            <button className="btn btn-link btn-sm dropdown-toggle" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                전체
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                <button className="dropdown-item">전체</button>
                                <button className="dropdown-item">초대</button>
                                <button className="dropdown-item">인증</button>
                                <button className="dropdown-item">무효</button>
                                <button className="dropdown-item">취소</button>
                            </div>
                        </div>
                        <div className="form-group mr-1">
                            <input id='hr-search' className='form-control form-control-sm'/>
                        </div>
                        <button className='btn btn-primary btn-sm'>검색</button>
                    </form>
                    <div className='flex-grow-1'/>
                    <button type='button' className='btn btn-outline-secondary'> <FontAwesomeIcon icon={['fal', 'user-plus']} /> 초대</button>
                </div>
                </div>
                <ul className="plain_list">
                    {this.state.content.map((data, index, list) => (
                        <li key={data.inviteeAddress} className='p-2 d-flex align-items-center'>
                            <div className='column_profile flex-grow-1 px-2'>
                            {data.invitee?(
                                <>
                                    {data.invitee.displayName}<br/>
                                    <small className='text-muted'>{data.inviteeAddress}</small>
                                </>
                            ):(
                                <span>{data.inviteeAddress}</span>
                            )}
                            </div>
                            <div className='column_role px-2'>{RoleLabel[data.role]}</div>
                            <div className='column_status px-2'> 
                                <span className={classNames("badge badge-pill", { [`badge-${StatusLabel[data.status].style}`]: true })}>{StatusLabel[data.status].label}</span>
                                <div className='dropdown ml-1 d-none'>
                                    <button type='button' 
                                        className={classNames('btn btn-sm btn-link')}
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <FontAwesomeIcon icon={['fal', 'ellipsis-h']} />
                                    </button>
                                    <div className='dropdown-menu'>
                                        <a className='dropdown-item' href='#'>Action</a>
                                    </div>
                                </div>                               
                            </div>
                        </li>
                    ))}
                </ul>
                <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-center">
                        <li className={classNames("page-item", {"disabled": this.state.paginationStart === 0})}>
                            <a className="page-link" href="#" aria-label="Previous">
                            <span aria-hidden="true">&laquo;</span>
                            </a>
                        </li>
                        {this.generatePageLinks(this.state.paginationStart, this.state.paginationSize, this.state.totalPages, this.state.number)}
                        <li className={classNames("page-item", {"disabled": this.state.paginationStart + this.state.paginationSize >=  this.state.totalPages})}>
                            <a className="page-link" href="#" aria-label="Next">
                            <span aria-hidden="true">&raquo;</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        );
    }

    generatePageLinks(start, count, totalPages, current){
        var pages = [];
        var limit = Math.min(start + count, totalPages)
        for(var i = start; i < limit; i++){
            let pageTo = i;
            pages.push(
                <li key={i} className={classNames("page-item", {"active" : current === i})}>
                    <a className="page-link" href="#" onClick={e => {e.stopPropagation(); this.fetchData(pageTo)}}>{i + 1}</a>
                </li>
            );
        }

        return pages;
    }
}

export default HRDProgramDetail